







































































































































import { Component, Watch, PropSync, Ref, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import Api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
// import { VesselUpsertModel } from "@/api/generated";
import VesselUpsertModel from "@/models/vesselUpsertModel";
import AgencyAutocomplete from "@/components/common/agency/AgencyAutocomplete.vue";
import VesselTypeAutocomplete from "@/components/common/vessel/VesselTypeAutocomplete.vue";
import { ContractType } from "@/api/generated";
import Discriminator from '@/types/Discriminator';
import getContractType from '../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { AgencyAutocomplete, VesselTypeAutocomplete } })
export default class VesselCreateDialog extends Validation {
  @Ref() private form!: VForm;
  @PropSync("dialog") private syncedDialog!: boolean;
  @Prop({default: Discriminator.VESSELS}) discriminator!: Discriminator;
  private doNotUse = false;

  private loading = false;
  private vessel: VesselUpsertModel = {
    agencyId: "",
    vesselTypeId: "",
    registrationNumber: "",
    tonnage: 0,
    name: "",
    rss: "",
    contactName: "",
    telephoneNumber: "",
    emailAddress: "",
    contactPostalAddress: "",
    doNotUse: false,
    discriminator: getContractType(this.discriminator)
  };

  get isCompaniesTable() {
    return this.discriminator == Discriminator.LAND_WORKERS
  }

  @Watch("syncedDialog")
  private onSyncedDialogChanged() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async onVesselCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.VesselService.apiVesselPost(this.vessel);
      snackbarModule.setSnackbarMessage((this.discriminator == Discriminator.LAND_WORKERS ? "Company" : "Vessel") + " Created");
      this.$emit("vessel-created");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create " + (this.discriminator == Discriminator.LAND_WORKERS ? "company" : "vessel"));
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
